import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { from, Observable, mergeMap } from 'rxjs';

interface Headers {
  [header: string]: string | string[];
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private afa: Auth,
  ) {}

  private async getHeaders(): Promise<Headers> {
    const headers: { Authorization?: string } = {};
    const user = await this.afa.currentUser;

    if (user) {
      headers.Authorization = `Bearer ${await user.getIdToken()}`;
    }

    return headers;
  }

  private getRoute(route: string) {
    return `/api${route}`;
  }

  private makeRequest<T>(route: string, callback: (fullRoute: string, headers: Headers) => Observable<T>) {
    const headers$ = from(this.getHeaders());

    return headers$.pipe(mergeMap((headers) => callback(this.getRoute(route), headers)));
  }

  public get<T>(route: string, params?: any) {
    return this.makeRequest(route, (fullRoute, headers) =>
      this.httpClient.get<T>(fullRoute, {
        headers,
        params,
      }),
    );
  }

  public post<T>(route: string, payload: any, params?: any) {
    return this.makeRequest(route, (fullRoute, headers) =>
      this.httpClient.post<T>(fullRoute, payload, {
        headers,
        params,
      }),
    );
  }

  public put<T>(route: string, payload: any, params?: any) {
    return this.makeRequest(route, (fullRoute, headers) =>
      this.httpClient.put<T>(fullRoute, payload, {
        headers,
        params,
      }),
    );
  }

  public patch<T>(route: string, payload: any, params?: any) {
    return this.makeRequest(route, (fullRoute, headers) =>
      this.httpClient.patch<T>(fullRoute, payload, {
        headers,
        params,
      }),
    );
  }

  public delete<T>(route: string, params?: any) {
    return this.makeRequest(route, (fullRoute, headers) =>
      this.httpClient.delete<T>(fullRoute, {
        headers,
        params,
      }),
    );
  }

  public getPromise<T>(route: string, params?: any) {
    return this.get<T>(route, params).toPromise();
  }

  public postPromise<T>(route: string, payload: any, params?: any) {
    return this.post<T>(route, payload, params).toPromise();
  }

  public putPromise<T>(route: string, payload: any, params?: any) {
    return this.put<T>(route, payload, params).toPromise();
  }

  public patchPromise<T>(route: string, payload: any, params?: any) {
    return this.patch<T>(route, payload, params).toPromise();
  }

  public deletePromise<T>(route: string, params?: any) {
    return this.delete<T>(route, params).toPromise();
  }

  public download<T>(route: string, method: string) {
    return this.makeRequest(route, (fullRoute, headers) =>
      this.httpClient[method.toLowerCase()]<T>(fullRoute, {
        headers,
        responseType: 'blob' as 'json',
        observe: 'response',
      }),
    ).toPromise();
  }
}
